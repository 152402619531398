
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Img from "@/components/general/Img.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DeleteModal from "@/components/general/DeleteModal.vue";
import NoteViewField from "@/components/users/userProfile/fields/NotesCardFields/NoteViewField.vue";
import NoteEditField from "@/components/users/userProfile/fields/NotesCardFields/NoteEditField.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "user-notes",
  components: {
    Img,
    DeleteModal,
    NoteViewField,
    NoteEditField
  },
  data() {
    return {
      note: "",
      selectedNoteID: "",
      selectedNoteComment: "",
      isEdited: false
    };
  },
  mounted() {
    this.fetchData(this.$route.params.id);
    setCurrentPageBreadcrumbs("Notes", ["User"]);
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    ...mapGetters("NotesModule", ["getData", "getError", "isAwaitingResponse"])
  },
  methods: {
    ...mapActions("NotesModule", [
      "fetchData",
      "createNew",
      "destroyData",
      "update",
      "clearErrors",
      "resetState"
    ]),
    saveNote() {
      this.clearErrors();
      this.createNew({
        comment: this.note,
        noteable_id: this.$route.params.id
      });
      this.note = "";
    },
    deleteNote() {
      this.destroyData({
        noteID: this.selectedNoteID,
        userID: this.$route.params.id
      });
    },
    saveEditedNote(newNote) {
      this.clearErrors();
      this.update({
        id: this.selectedNoteID,
        comment: newNote,
        userID: this.$route.params.id
      });
      this.isEdited = false;
    },
    openSWAL() {
      Swal.fire({
        position: "center",
        icon: this.getError ? "error" : "success",
        title: this.getError ? "Invalid data" : "Note saved",
        showConfirmButton: this.getError ? true : false,
        timer: this.getError ? 1200 : 800
      });
      // Swal.fire({
      //   text: this.getError ? this.getError : "Note saved",
      //   icon: this.getError ? "error" : "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn btn-primary"
      //   }
      // }).then(() => {
      //   //
      // });
    }
  },
  watch: {
    isAwaitingResponse() {
      if (!this.isAwaitingResponse) this.openSWAL();
    }
  },
  unmounted() {
    this.resetState();
  }
});
