<template>
  <div class="mt-5 mb-5">
    <textarea rows="2" class="w-100 border-1" v-model="note"></textarea>
  </div>
  <div class="separator mb-3"></div>
  <div class="d-flex justify-content-end mb-3 align-items-center">
    <span class="btn btn-icon btn-sm btn-active-color-primary ps-0">
      <span
        @click="$emit('discard')"
        class="svg-icon me-2 svg-icon-primary svg-icon-2x"
        ><!--Begin::Svg Icon--><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <rect
              fill="#000000"
              opacity="0.3"
              transform="translate(14.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-14.000000, -12.000000) "
              x="13"
              y="5"
              width="2"
              height="14"
              rx="1"
            />
            <rect
              fill="#000000"
              opacity="0.3"
              x="3"
              y="3"
              width="2"
              height="18"
              rx="1"
            />
            <path
              d="M5.7071045,15.7071045 C5.3165802,16.0976288 4.68341522,16.0976288 4.29289093,15.7071045 C3.90236664,15.3165802 3.90236664,14.6834152 4.29289093,14.2928909 L10.2928909,8.29289093 C10.6714699,7.914312 11.2810563,7.90106637 11.6757223,8.26284357 L17.6757223,13.7628436 C18.0828413,14.136036 18.1103443,14.7686034 17.7371519,15.1757223 C17.3639594,15.5828413 16.7313921,15.6103443 16.3242731,15.2371519 L11.0300735,10.3841355 L5.7071045,15.7071045 Z"
              fill="#000000"
              fill-rule="nonzero"
              transform="translate(11.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-11.000001, -11.999997) "
            />
          </g></svg
        ><!--end::Svg Icon--></span
      ></span
    >
    <span
      class="btn btn-icon btn-sm btn-active-color-primary ps-0"
      @click="$emit('save', note)"
    >
     <!--Begin::Svg Icon-->
      <span class="svg-icon svg-icon-primary svg-icon-2x"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
              fill="#000000"
              fill-rule="nonzero"
            />
            <rect
              fill="#000000"
              opacity="0.3"
              x="12"
              y="4"
              width="3"
              height="5"
              rx="0.5"
            />
          </g></svg
        ><!--end::Svg Icon--></span
      >
    </span>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "notes-card-edit-field",
  props: {
    comment: Object
  },
  data() {
    return {
      note: ""
    };
  },
  mounted() {
    this.note = this.comment;
  }
});
</script>
