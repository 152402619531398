<template>
  <div class="mb-5 d-flex h-100 w-100 align-items-center">
    <div class="d-flex flex-stack w-100 position-relative">

      <div class="symbol symbol-45px me-5">
        <Img :src="note.commentator.photo_url" />
      </div>
      <div
        style="left:55px"
        class="position-absolute h-100 w-4px bg-secondary rounded top-0"
      ></div>
      <div class="w-100 fw-semibold ms-4 text-gray-600">
        <div class="fs-5">
          {{ note.created_at }}
        </div>
        <span
          style="word-break: break-all"
          class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
        >
          {{ note.comment }}
        </span>
        <div class="text-gray-500">
          Created by
          <router-link :to="`/users/${note.commentator.id}/personal`">{{
            note.commentator.name
          }}</router-link>
        </div>
      </div>
      <div class="ps-5" style="min-width: 157px !important;">
        <a
          @click="$emit('edit', { text: note.comment, id: note.id })"
          class="me-3 btn btn-bg-light btn-active-color-primary btn-sm"
          >Edit</a
        >
        <a
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#deleteModal"
          @click="$emit('openDeleteModal', note.id)"
          class="btn btn-bg-light btn-active-color-primary btn-sm"
          >Delete</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "notes-card-view-field",
  props: {
    note: Object
  }
});
</script>
